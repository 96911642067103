import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

export function useQueryString() {
	const [searchParams] = useSearchParams();

	const useQueryStringResult = useMemo(() => {
		function tryParseQueryStringValue({
			name,
			isList,
			parser,
		}: {
			name: string;
			isList?: boolean;
			parser?: (value: string) => any;
		}) {
			function getQueryStringValue() {
				const value = searchParams.get(name);
				// null values are treated as a valid initial form value where as undefined is ignored
				return value === null ? undefined : value;
			}

			const queryStringValue = getQueryStringValue();

			if (!queryStringValue) {
				return queryStringValue;
			}

			if (isList) {
				const splitQueryString = queryStringValue.split('|');
				return parser ? splitQueryString.map(parser) : splitQueryString;
			}

			return parser ? parser(queryStringValue) : queryStringValue;
		}

		return {
			tryParseQueryStringValue,
		};
	}, [searchParams]);

	return useQueryStringResult;
}

import React, { lazy, Suspense } from 'react';

import { createRoot } from 'react-dom/client';

import { AuthProvider } from '@pushpay/auth';
import Reset from '@pushpay/reset';
import { createUseStyles } from '@pushpay/styles';
import { BifrostTheme, ThemeProvider } from '@pushpay/theming';
import { memo } from '@pushpay/types';

import { browserHistory } from './components/router';
import getAppSettings from './get-app-settings';
import { initRaygun } from './raygun';

const LazyApp = lazy(() => import(/* webpackPreload: true, webpackChunkName: 'lazy-app' */ './lazy-app'));

const useStyles = createUseStyles({
	'@global': {
		'.app-container': {
			display: 'flex',
			flexGrow: 1,
			flexDirection: 'column',
		},
	},
});

const App = memo(function App() {
	useStyles(undefined);
	const { authConfiguration } = getAppSettings();

	return (
		<ThemeProvider theme={BifrostTheme}>
			<Reset />
			<AuthProvider history={browserHistory} config={authConfiguration} renderError={error => error}>
				<Suspense fallback={null}>
					<LazyApp />
				</Suspense>
			</AuthProvider>
		</ThemeProvider>
	);
});

initRaygun();
const rootElement = document.getElementById('app');
// eslint-disable-next-line spaced-comment
/*	// Toggle-comment for debugging component rendering; https://github.com/welldone-software/why-did-you-render
async function renderApp() {
	if (process.env.NODE_ENV === 'development') {
		const { default: whyDidYouRender } = await import('@welldone-software/why-did-you-render');
		whyDidYouRender(React, {
			trackAllPureComponents: true,
		});
	}
	if (rootElement) {
		createRoot(rootElement).render(<App />);
	}
}
renderApp();
// eslint-disable-next-line spaced-comment
/*/
if (rootElement) {
	createRoot(rootElement).render(<App />);
}
/**/

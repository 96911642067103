import { RaygunV2UserDetails } from 'raygun4js';

import getAppSettings from '../get-app-settings';

export const initRaygun = () => {
	const { version, raygunApiKey } = getAppSettings();
	if (raygunApiKey) {
		rg4js('apiKey', raygunApiKey);
		rg4js('setVersion', version);
		rg4js('enableCrashReporting', true);
		rg4js('options', {
			ignoreAjaxAbort: true,
			ignoreAjaxError: true,
			captureUnhandledRejections: true,
		});
		rg4js('saveIfOffline', true);
		rg4js('enablePulse', true);
		rg4js('logContentsOfXhrCalls', false);
	}
};

export const setUserContextForRaygun = (userContext: RaygunV2UserDetails) => {
	rg4js('setUser', { ...userContext, isAnonymous: false });
};

export const clearUserContextForRaygun = () => {
	rg4js('setUser', { identifier: '', isAnonymous: true });
};

export const send = (error: Error, customData?: Record<string, any>[]) => rg4js('send', { error, customData });

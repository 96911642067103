import { send } from './config';

export function onUnmappableValidationErrors(fieldPaths: string[], data: any) {
	const error = new Error('Unable to map form validation error field paths back to the UI');
	send(error, [
		{
			fieldPaths,
			data,
		},
	]);
}
